export const projects = [
  {
    title: 'Only Homes',
    subtitle: 'Website',
    textColor: 'text-[#F64B29]',
    bgButton: 'bg-[#F64B29]',
    backgroundColor: 'bg-[#F2F5F6]',
    query: 'onlyhomestulum',
    img: '/assets/projects/onlyhomes.png',
    slogan: 'No apartments no condos just homes',
    description:
      'Only Homes Tulum es una agencia inmobiliaria enfocada únicamente en la venta de casas con un concepto chic y ecológico en el caribe mexicano. Nuestra participación en este proyecto fue desde la creación del diseño UX/UI hasta el desarrollo del sitio web. Los objetivos principales del proyecto fuerón mantener un diseño limpio y moderno que evocará a la naturaleza y al mismo tiempo que fuera facil y barato de mantener.',
    resultTitle: 'Optimización de los recursos económicos',
    resultContent:
      'Cuando comenzamos este proyecto teniamos en claro 2 cosas, que el presupuesto era limitado y que el contenido debía ser fácil de actualizar. Inmediatamente cuando entendimos los recursos con los que disponiamos y los objetivos a alcanzar, descartamos la opción de implementar un sistema totalmente a la medida con alguna tecnología MERNStack o similar, ya que esta solución nos sacaba del presupuesto establecido por el cliente, de manera que decidimos desarrollar el sitio web en Wordpress un CMS de bajo costo y que al mismo tiempo permite la gestión de contenido de manera fácil. \n\n Si bien es cierto que usar Wordpress puede generar limitantes y desventajas, esto no impactaba de manera significativa en la satisfacción de los requerimientos y necesidades. El diseño UX/UI se creo en Adobe xD y el sitio web se desarrollo en Wordpress usando como base un template',
    imagesSmallSlider: [
      '/assets/clients/onlyhomes/onlyhomes-1.jpg',
      '/assets/clients/onlyhomes/onlyhomes-2.jpg',
      '/assets/clients/onlyhomes/onlyhomes-3.jpg',
      '/assets/clients/onlyhomes/onlyhomes-4.jpg',
      '/assets/clients/onlyhomes/onlyhomes-5.jpg',
      '/assets/clients/onlyhomes/onlyhomes-6.jpg',
      '/assets/clients/onlyhomes/onlyhomes-7.jpg',
    ],
    imagesBigSlider: [
      '/assets/clients/onlyhomes/onlyhomes-8.jpg',
      '/assets/clients/onlyhomes/onlyhomes-9.jpg',
      '/assets/clients/onlyhomes/onlyhomes-10.jpg',
      '/assets/clients/onlyhomes/onlyhomes-11.jpg',
      '/assets/clients/onlyhomes/onlyhomes-12.jpg',
    ],
    services: 'Diseño y desarrollo web',
    location: 'Tulum Quintana Roo, México',
    url: 'onlyhomestulum.com',
  },
  {
    title: 'Boatabroad',
    subtitle: 'Website',
    textColor: 'text-[#00BFC1]',
    bgButton: 'bg-[#00BFC1]',
    backgroundColor: 'bg-[#f9f9f9]',
    query: 'boatabroad',
    img: '/assets/projects/boatabroad.png',
    slogan: 'Sail the world',
    description:
      'Boatabroad es un marketplace que conecta propietarios de barcos con navegantes en busca de rentar un barco. Los objetivos principales del proyecto fuerón desarrollar un sistema totalmente a la medida, escalable y con una interfaz facil de utilizar.',
    resultTitle: 'Planeación y ejecución',
    resultContent:
      'El reto más grande en este proyecto fue aterrizar la idea del cliente a un plan de trabajo real. Uno de los objetivos principales del proyecto fue crear las bases de un sistema que pudiera escalarse en el tiempo conforme el MVP fuera validandose en el mercado, debido a esto sabiamos que teniamos que tener el control total en cada uno de los bloques que conformasen el desarrollo. \n\n Nuestro trabajo abarco desde el branding hasta la fase de desarrollo y despliegue del sitio web. El sitio web se desarrollo usando MERN stack (MongoDB, Express.js, React y Node.js), también se desarrollo un dashboard totalmente a la medida donde el administrador valida los anuncios sin necesidad de tocar una linea de código.',
    imagesSmallSlider: [
      '/assets/clients/boatabroad/boatabroad-8.jpg',
      '/assets/clients/boatabroad/boatabroad-9.jpg',
      '/assets/clients/boatabroad/boatabroad-10.jpg',
      '/assets/clients/boatabroad/boatabroad-11.jpg',
      '/assets/clients/boatabroad/boatabroad-12.jpg',
      '/assets/clients/boatabroad/boatabroad-13.jpg',
      '/assets/clients/boatabroad/boatabroad-14.jpg',
    ],
    imagesBigSlider: [
      '/assets/clients/boatabroad/boatabroad-1.jpg',
      '/assets/clients/boatabroad/boatabroad-2.jpg',
      '/assets/clients/boatabroad/boatabroad-3.jpg',
      '/assets/clients/boatabroad/boatabroad-4.jpg',
      '/assets/clients/boatabroad/boatabroad-5.jpg',
      '/assets/clients/boatabroad/boatabroad-6.jpg',
      '/assets/clients/boatabroad/boatabroad-7.jpg',
    ],
    services: 'Desarrollo web y branding',
    location: 'Tulum Quintana Roo, México',
    url: 'boatabroad.com',
  },
  {
    title: 'Aura',
    subtitle: 'Website',
    textColor: 'text-[#0060FF]',
    bgButton: 'bg-[#0060FF]',
    backgroundColor: 'bg-[#F2F2F2]',
    query: 'aura',
    img: '/assets/projects/aura.png',
    slogan: 'Find properties on the best beaches in México',
    description:
      'Aura Real Estate es una agencia inmobiliaria que promueve propiedades en las mejores playas de México. Cuando fuimos contratados el objetivo era claro, su sitio web debia ser sencillo, pero que transmitiera seguridad y elegancia en cada rincón, además de poder ser gestionado el contenido de manera fácil y rápida.',
    resultTitle: 'Un buen diseño',
    resultContent:
      'Nuestro trabajo en este proyecto abarco desde el branding, hasta el desarrollo del sitio web. El sitio web para Aura fue desarrollado en el CMS open source Wordpress y un template de base. El objetivo principal del desarrollo era crear un sitio web que fuera fácil de utilizar e intuitivo donde pudiera verse de manera detallada y concisa la información de cada una de las propiedades, manteniendo un diseño moderno y elegante.',
    imagesSmallSlider: [
      '/assets/clients/aura/aura-6.jpg',
      '/assets/clients/aura/aura-7.jpg',
      '/assets/clients/aura/aura-8.jpg',
      '/assets/clients/aura/aura-9.jpg',
      '/assets/clients/aura/aura-10.jpg',
      '/assets/clients/aura/aura-11.jpg',
      '/assets/clients/aura/aura-12.jpg',
      '/assets/clients/aura/aura-13.jpg',
    ],
    imagesBigSlider: [
      '/assets/clients/aura/aura-2.jpg',
      '/assets/clients/aura/aura-1.jpg',
      '/assets/clients/aura/aura-3.jpg',
      '/assets/clients/aura/aura-4.jpg',
      '/assets/clients/aura/aura-5.jpg',
    ],
    services: 'Desarrollo web y branding',
    location: 'Tulum Quintana Roo, México',
    url: 'aurarealestate.mx',
  },
  {
    title: 'Cálido',
    subtitle: 'Website',
    textColor: 'text-[#A58861]',
    bgButton: 'bg-[#A58861]',
    backgroundColor: 'bg-[#f9f9f9]',
    query: 'calido',
    img: '/assets/projects/calido.png',
    slogan: 'Un lugar cálido',
    description:
      'Cálido Furniture and Deco es una empresa ubicada en Tulum, dedicada a la comercialización de muebles y textiles. Nuestra trabajo en Cálido Furniture and Deco consistió desde la creación del diseño UX/UI hasta el desarrollo web',
    resultTitle: 'Diseño y desarrollo web',
    resultContent:
      'El sitio web de calido se construyo en Wordpress y un template base, con la finalidad de facilitar la gestión del contenido. Se crear las bases para en un futuro escalar el sitio a un ecommerce haciendo uso de Woocommerce y pasarelas de pago como paypal y stripe',
    imagesSmallSlider: [
      '/assets/clients/calido/calido-1.jpg',
      '/assets/clients/calido/calido-2.jpg',
      '/assets/clients/calido/calido-3.jpg',
      '/assets/clients/calido/calido-4.jpg',
      '/assets/clients/calido/calido-5.jpg',
      '/assets/clients/calido/calido-6.jpg',
      '/assets/clients/calido/calido-7.jpg',
    ],
    imagesBigSlider: [
      '/assets/clients/calido/calido-8.jpg',
      '/assets/clients/calido/calido-9.jpg',
      '/assets/clients/calido/calido-10.jpg',
      '/assets/clients/calido/calido-11.jpg',
      '/assets/clients/calido/calido-12.jpg',
      '/assets/clients/calido/calido-13.jpg',
    ],
    services: 'Diseño y desarrollo web',
    location: 'Tulum Quintana Roo, México',
    url: 'calidomx.com',
  },
  {
    title: 'Bellepool',
    subtitle: 'Website',
    textColor: 'text-[#0582ca]',
    bgButton: 'bg-[#0582ca]',
    backgroundColor: 'bg-[#E7ECEF]',
    query: 'Bellepool',
    img: '/assets/projects/bellepool.png',
    slogan: 'Albercas siempre bellas',
    description:
      'Bellepool es una empresa dedicada a la construcción y mantenimiento de albercas. Nuestro trabajo en Bellepool consistio en diseñar y desarrollar su tienda online. Como requisito del cliente el sitio web debía desarrollarse en Wordpress y Woocommerce',
    resultTitle: 'Desarrollo rápido',
    resultContent:
      'Cuando comenzamos este proyecto nuestro cliente requería tener listo su sitio web en pocos días, una de las grandes ventajas de desarrollar un sitio web sobre una arquitectura base como es el caso de Wordpress es la reducción de los tiempos de desarrollo. Cuando diseñamos este sitio web, queriamos transmitir una sensación de frescura que recordasé al agua evocando al concepto principal de la marca.',
    imagesSmallSlider: [
      '/assets/clients/bellepool/bellepool-1.jpg',
      '/assets/clients/bellepool/bellepool-2.jpg',
      '/assets/clients/bellepool/bellepool-3.jpg',
      '/assets/clients/bellepool/bellepool-4.jpg',
      '/assets/clients/bellepool/bellepool-5.jpg',
      '/assets/clients/bellepool/bellepool-6.jpg',
      '/assets/clients/bellepool/bellepool-7.jpg',
    ],
    imagesBigSlider: [
      '/assets/clients/bellepool/bellepool-8.jpg',
      '/assets/clients/bellepool/bellepool-11.jpg',
      '/assets/clients/bellepool/bellepool-9.jpg',
      '/assets/clients/bellepool/bellepool-10.jpg',
    ],
    services: 'Diseño y desarrollo web',
    location: 'Tulum Quintana Roo, México',
    url: 'bellepool.com',
  },
];
