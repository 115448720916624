export const LINKS = [
  {
    translationKey: 'menuPopUp.sections.home',
    path: '/',
  },
  {
    translationKey: 'menuPopUp.sections.portfolio',
    path: '/portfolio',
  },
  {
    translationKey: 'menuPopUp.sections.services',
    path: '/services',
  },
  {
    translationKey: 'menuPopUp.sections.contact',
    path: '/contact',
  },
];
